* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
 .Chat {
    width: 400px;
} 



.react-chatbot-kit-chat-bot-message {
    display: flex; 
    align-items: flex-start; 
    margin: 10px 0;
    background-color: #d10329;
}
.react-chatbot-kit-chat-container {
   width: 300px;
}
.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid #f10732;
}
.react-chatbot-kit-chat-btn-send {
    background-color: #f10732;
}


.react-chatbot-kit-chat-inner-container {
    background-color: #757575;
}

.react-chatbot-kit-chat-header {
    background-color: #5a5959;
    color: antiquewhite;
}

.img-component {
    margin-left: 3rem;
    position: relative;
}

.img-container {
    width: 185px;
    height: 185px;
    border: 2px solid #f3eef3;
    overflow: hidden;
    border-radius: 5px;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-component a {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: #61045F;
    color: white;
    text-decoration: none;
    padding: .5rem 1rem;
}

.img-component a:hover {
    background-color: #61045f5e;
}



    
.calendar-container {
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; 
    
}


